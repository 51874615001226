.app-theme {
  --fontFamily-heading: var(--fontFamily-sans, system-ui);
  --fontFamily-text: var(--fontFamily-sans, system-ui);

  --fontWeight-extraLargeHeading: 600;
  --fontWeight-largeHeading: 600;
  --fontWeight-mediumHeading: 600;
  --fontWeight-smallHeading: 600;
  --fontWeight-extraSmallHeading: 600;
  --fontWeight-tinyHeading: 600;
  --fontWeight-largeText: 400;
  --fontWeight-mediumText: 400;
  --fontWeight-smallText: 400;

  --lineHeight-mediumHeading: 1.17em;
  --lineHeight-smallHeading: 1.17em;
  --lineHeight-extraLargeHeading: 1.17em;
  --lineHeight-mediumText: 1.5em;
  --lineHeight-largeText: 1.5em;
  --lineHeight-largeHeading: 1.17em;
  --lineHeight-smallText: 1.5em;
  --lineHeight-extraSmallHeading: 1.17em;
  --lineHeight-tinyHeading: 1.17em;

  --letterSpacing-mediumHeading: 0;
  --letterSpacing-smallHeading: 0;
  --letterSpacing-extraLargeHeading: 0;
  --letterSpacing-mediumText: 0;
  --letterSpacing-largeText: 0;
  --letterSpacing-largeHeading: 0;
  --letterSpacing-smallText: 0;
  --letterSpacing-extraSmallHeading: 0;
  --letterSpacing-tinyHeading: 0;

  --radius-xl: 8px;
  --radius-2Xl: 12px;
  --radius-3Xl: 16px;
  --radius-md: 4px;
  --radius-4Xl: 20px;
  --radius-sm: 2px;
  --radius-none: 0;
  --radius-lg: 6px;
  --radius-full: 9999px;

  --colors-white: 0 0% 100%;
  --colors-black: 0 0% 0%;
  --colors-secondary1: 340 100% 99.4%;
  --colors-secondary2: 345 100% 98.4%;
  --colors-secondary3: 348 90.9% 95.7%;
  --colors-secondary4: 346 88.2% 93.3%;
  --colors-secondary5: 345.8 90.2% 88%;
  --colors-secondary6: 345.2 100% 82.5%;
  --colors-secondary7: 345.1 72.5% 62.9%;
  --colors-secondary8: 345.2 81.9% 47.6%;
  --colors-secondary9: 345.6 88.1% 23.1%;
  --colors-secondary10: 346.2 100% 2.5%;
  --colors-gray1: 180 20% 99%;
  --colors-gray2: 180 6.7% 97.1%;
  --colors-gray3: 180 3.2% 93.9%;
  --colors-gray4: 180 2.4% 92%;
  --colors-gray5: 180 2% 90%;
  --colors-gray6: 180 2.1% 81.6%;
  --colors-gray7: 180 2.4% 59%;
  --colors-gray8: 180 2% 40%;
  --colors-gray9: 180 2% 40%;
  --colors-gray10: 180 2.4% 8%;
  --colors-success1: 144 71.4% 98.6%;
  --colors-success2: 140 42.9% 95.9%;
  --colors-success3: 140 47.4% 88.8%;
  --colors-success4: 140.4 55.3% 83.3%;
  --colors-success5: 139.7 54.7% 79.2%;
  --colors-success6: 140.5 44.6% 63.9%;
  --colors-success7: 140 82.1% 35.1%;
  --colors-success8: 139.8 81.9% 28.2%;
  --colors-success9: 140 100% 12.9%;
  --colors-success10: 138.5 100% 2.5%;
  --colors-warning1: 36 100% 99%;
  --colors-warning2: 40 100% 97.1%;
  --colors-warning3: 40 96.2% 89.6%;
  --colors-warning4: 39.7 97.3% 85.7%;
  --colors-warning5: 40 98.1% 79.6%;
  --colors-warning6: 39.9 100% 72%;
  --colors-warning7: 40 98.3% 54.5%;
  --colors-warning8: 40 81.8% 45.3%;
  --colors-warning9: 40 98.1% 21%;
  --colors-warning10: 41.5 100% 2.5%;
  --colors-danger1: 0 100% 99.4%;
  --colors-danger2: 0 100% 98.4%;
  --colors-danger3: 0 90.9% 95.7%;
  --colors-danger4: 0 88.2% 93.3%;
  --colors-danger5: 0 90.2% 88%;
  --colors-danger6: 0 100% 82.5%;
  --colors-danger7: 0 72.5% 62.9%;
  --colors-danger8: 0 81.9% 47.6%;
  --colors-danger9: 0 88.1% 23.1%;
  --colors-danger10: 0 100% 2.5%;
  --colors-tertiary1: 36 100% 99%;
  --colors-tertiary2: 32 100% 97.1%;
  --colors-tertiary3: 34.1 96.2% 89.6%;
  --colors-tertiary4: 34.6 97.3% 85.7%;
  --colors-tertiary5: 34.7 98.1% 79.6%;
  --colors-tertiary6: 34.4 100% 72%;
  --colors-tertiary7: 34.5 98.3% 54.5%;
  --colors-tertiary8: 34.3 81.8% 45.3%;
  --colors-tertiary9: 34.3 98.1% 21%;
  --colors-tertiary10: 32.3 100% 2.5%;
  --colors-primary1: 180 71.4% 98.6%;
  --colors-primary2: 180 42.9% 95.9%;
  --colors-primary3: 175.6 47.4% 88.8%;
  --colors-primary4: 176.2 55.3% 83.3%;
  --colors-primary5: 176.9 54.7% 79.2%;
  --colors-primary6: 177.1 44.6% 63.9%;
  --colors-primary7: 177.1 82.1% 35.1%;
  --colors-primary8: 176.9 81.9% 28.2%;
  --colors-primary9: 177.3 100% 12.9%;
  --colors-primary10: 180 100% 2.5%;
  --colors-info1: 216 100% 99%;
  --colors-info2: 214.3 87.5% 96.9%;
  --colors-info3: 214.3 87.5% 93.7%;
  --colors-info4: 212.6 85.4% 92%;
  --colors-info5: 212.4 88.7% 86.1%;
  --colors-info6: 213.1 39.8% 61.6%;
  --colors-info7: 213.1 66.5% 43.3%;
  --colors-info8: 212.9 66.2% 30.2%;
  --colors-info9: 212.8 67.1% 15.5%;
  --colors-info10: 210 85.7% 2.7%;

  --shadow-sm: 0px 1px 3px 0px #11181C12;
  --shadow-md: 0px 2px 8px 0px #11181C12;
  --shadow-lg: 0px 2px 32px 0px #11181C12;

  /* Responsive Theme Values */
  --space1: 4px;
  --space1-5: 6px;
  --space2: 8px;
  --space2-5: 10px;
  --space3: 12px;
  --space4: 16px;
  --space5: 20px;
  --space6: 24px;
  --space7: 32px;
  --space8: 40px;
  --space9: 48px;
  --space10: 64px;
  --space11: 96px;
  --space12: 128px;

  --fontSize-extraLargeHeading: 2rem;
  --fontSize-largeHeading: 1.5rem;
  --fontSize-mediumHeading: 1.25rem;
  --fontSize-smallHeading: 1.15rem;
  --fontSize-extraSmallHeading: 1rem;
  --fontSize-tinyHeading: 0.875rem;

  --fontSize-largeText: 1rem;
  --fontSize-mediumText: 0.875rem;
  --fontSize-smallText: 0.75rem;
}

@media screen and (min-width: 767px) {
  .app-theme {
    --fontSize-extraLargeHeading: 3rem;
    --fontSize-largeHeading: 2rem;
    --fontSize-mediumHeading: 1.5rem;
    --fontSize-smallHeading: 1.25rem;
    --fontSize-extraSmallHeading: 1rem;
    --fontSize-tinyHeading: 0.875rem;

    --fontSize-largeText: 1rem;
    --fontSize-mediumText: 0.875rem;
    --fontSize-smallText: 0.75rem;
  }
}
